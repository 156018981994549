@use '../../../scss/' as *;
.img-box {
    @include transition3;
    position: relative;
    padding: 20px;  
    background: #2E1A48;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        height: 12em;
        width: 12em;
        object-fit: cover;
    }
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid #00AFF0;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &::before {
        @include transition3;
        position: absolute;
        content: "";
        border: 2px solid rgba(34, 183, 143, 0);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #00AFF0;
            border: 2px solid #00AFF0;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        z-index: 1;
        font-size: 20px;
        line-height: 1.4;
        text-transform: uppercase;
        color: $white;
        font-family: 'cybox', sans-serif;
        .artist {
            font-size: 12px;
            text-transform: none;
        }
    }
    &:hover {
        cursor: pointer;
    }
    width: calc(100% - 10px);
    flex: 0 0 calc(25% - 20px);
    margin: 0 10px 20px;
    @media only screen and (max-width: 1024px) {
        flex: 0 0 calc(33.3333% - 20px);
    }
    @media only screen and (max-width: 768px) {
        flex: 0 0 calc(50% - 20px);
    }
    @media only screen and (max-width: 650px) {
         flex: 0 0 calc(100% - 20px);
    }
}

.home-2 .img-box {
    
}

.page-nft {
    .img-box {
        
        
    }
    .tf-button {
        margin-top: 0;
    }
}