.container-popup-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}

#popup {
  position: absolute;
  background: #2e1a48;
  border: 2px solid #016ba1;
  font-family: "Chakra Petch", sans-serif;
  overflow: auto;
  border-radius: 4px;
  padding: 20px;
  color: white;
  font-weight: 600;
  font-size: larger;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  z-index: 1200;

  button {
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    font-family: "Chakra Petch", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    padding: 9px 32px;
    height: 48px;
    margin-top: 20px;
    border: none;
  }
}
