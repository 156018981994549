@use '../../scss/' as *;
.widget-social {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
        margin: 0 11px 0 12px;
    }
}
#footer {
    display: none;
    .footer-main {
        padding: 88px 0 80px;
        text-align: center;
        position: relative;
        background: $bg-3;
        .bg1,
        .bg2 {
            position: absolute;
        }
        .bg1 {
            top: 0;
            left: 8%;
        }
        .bg2 {
            bottom: 0;
            right: 10%;
        }
        .widget-social {
            margin-bottom: 41px;
        }
        .heading {
            font-size: 32px;
            line-height: 1.375;
            text-transform: capitalize;
            margin-bottom: 16px;
            letter-spacing: 0;
        }
        p {
            text-transform: capitalize;
        }
        #subscribe-form {
            max-width: 778px;
            margin-top: 48px;
            height: 64px;
        }
    }
    .footer-bottom {
        margin-top: 1px;
        padding: 17px 0 15px;
        background: $bg-3;
        .wrap-fx {
            @include flex(center,space-between);
            .Copyright {
                p {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.57;
                    text-transform: capitalize;
                }
            }
            .list {
                @include flex2;
                li {
                    margin-left: 24px;
                    a {
                        font-size: 14px;
                        line-height: 1.57;
                    }
                }
            }
            @include tablet {
                justify-content: center;
            }
        }
    }
}

#subscribe-form {
    position: relative;
    @include flex(center,flex-start);
    button {
        position: absolute;
        right: 0;
        top: 0;
        letter-spacing: 0;
        height: 64px;
        border: none;
        background: $bg-2;
        text-transform: capitalize;
        font-weight: 700;
        width: 345px;
        &.tf-button::before {
            background: $white;
        }
        &:hover {
            color: #fff;
            background: transparent
        }
    }
    input {
        @include transition3;
        border: 2px solid #00AFF0;
        width: 74.5%;;
        padding-left: 18px;
        &::placeholder {
            text-transform: capitalize;
        }
        &:focus {
            border-color: $color-hover;
        }
    }
}

#scroll-top {
    position             : fixed;
    display              : block;
    width                : 50px;
    height               : 50px;
    line-height          : 50px;
    border-radius        : 4px;
    text-align           : center;
    z-index              : 999;
    right                : 14px;
    bottom               : 23px;
    border-radius        : 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius   : 50%;
    -ms-border-radius    : 50%;
    -o-border-radius     : 50%;
    cursor               : pointer;
    overflow             : hidden;
}

#scroll-top.show {
    right     : 15px;
    opacity   : 1;
    visibility: visible;
}

#scroll-top:before,
#scroll-top:after {
    content           : "";
    position          : absolute;
    top               : 0;
    left              : 0;
    width             : 100%;
    height            : 100%;
    @include transition3;
}

#scroll-top:before {
    z-index         : -1;
    background: $bg-2;
}


#scroll-top:after {
    content    : "\f077";
    font-family: "Font Awesome 5 Pro";
    font-size  : 18px;
    color      : #ffffff;
    font-weight: 600;
}

#scroll-top:hover {
    transform: translateY(-7%);
}
